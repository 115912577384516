import React, { useState, useEffect } from "react";
import { ImageUrl } from "../../utils/constants"; 
import PropTypes from "prop-types";
import { RecommendedContainer } from "./styles";
import { ChallengeHeader } from "../../components/ChallengeDashboardV2/styles"; 
import SkeletonLoder from "../../components/common/skeletonLoder";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import parse from 'react-html-parser';
import LazyImage from "../../components/common/LazyImage/LazyImage";
import { fetchApi } from "../../utils/methods";
import { toast } from "react-toastify";
import { GetThemes } from '../../redux/constants/apiConstants';

const RecommendedQuiz = ({
  RecommendedEventData =[],
  margin,
  history,
  RecommendedEventDataLoading,
  // heading,
  isWellness
}) => {
  // const [activeRecommendation, setActiveRecommendation] = useState(
  //   RecommendedEventData[0]
  // );
  const [activeRecommendation, setActiveRecommendation] = useState(
    RecommendedEventData && RecommendedEventData.length ? RecommendedEventData[0] : null
  );
  const [bannerData, setBannerData] = useState(RecommendedEventData || []);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isInside/*,setIsInside*/] = useState(false);
  const [featuredThemes, setFeaturedThemes] = useState([]);
  // const [bannerData, setBannerData] = useState(RecommendedEventData);

  // useEffect(() => {
  //   if (RecommendedEventData && RecommendedEventData.length) {
  //     setActiveRecommendation(RecommendedEventData[0]);
  //     setBannerData(RecommendedEventData);
  //   }

  //   const interval = window.setInterval(() => {
  //     if (!isInside && bannerData.length) {
  //       setActiveIndex((prevIndex) => {
  //         const newIndex = (prevIndex + 1) % bannerData.length;
  //         setActiveRecommendation(bannerData[newIndex]);
  //         return newIndex;
  //       });
  //     }
  //   }, 8000);

  //   return () => window.clearInterval(interval);
  // }, [isInside, bannerData]);

  //   const onQuizDetailsRedirection = (id) => {
  //     history.push({pathname:`/education/adventure/quiz-details/${id}`});
  //   };

  //   window.console.log("featuredThemes",featuredThemes)

  useEffect(() => {
    const GetFeaturedThemes = async () => {
      const apiUrl = GetThemes;
      try {
        const res = await fetchApi(apiUrl, "GET");
        if (res.status === "success") {
          setFeaturedThemes(res.data); 
        } else {
          toast.error(res.error);
        }
      } catch (error) {
        window.console.log(error);
      }
    };

    GetFeaturedThemes(); 

    if (RecommendedEventData && RecommendedEventData.length) {
      setActiveRecommendation(RecommendedEventData[0]);
      setBannerData(RecommendedEventData);
    }

    const interval = window.setInterval(() => {
      if (!isInside && bannerData.length) {
        setActiveIndex((prevIndex) => {
          const newIndex = (prevIndex + 1) % bannerData.length;
          setActiveRecommendation(bannerData[newIndex]);
          return newIndex;
        });
      }
    }, 8000);

    return () => window.clearInterval(interval);
  }, [isInside, bannerData, RecommendedEventData]);

  const onQuizDetailsRedirection = (id) => {
    history.push({
      pathname: `/wellness-resource/quiz-details/${id}`,
    });
  };

  // window.console.log("RecommendedEventData",RecommendedEventData)
  return (
    <React.Fragment>
      <ChallengeHeader
        style={{ color: "#005c87", lineHeight: "24px", marginTop: margin }}
      >
      Featured:  {featuredThemes?.length > 0 ? ` ${featuredThemes[0][0]?.month}’s  ${featuredThemes[0][0]?.theme_text}` : "Recommended Quizzes"} 
      </ChallengeHeader>

      {RecommendedEventDataLoading ? (
        <React.Fragment>
          {" "}
          <SkeletonLoder
            width={"1248px"}
            height={"450px"}
          ></SkeletonLoder>
        </React.Fragment>
      ) : (
        <RecommendedContainer
          background={
            activeRecommendation?.image ? activeRecommendation?.image : "white"
          }
        >
          <div
            className="row"
          >
            <div className="col-1">
              <div className="details">
                <div className="title">
                  <span className="title-text">
                    { activeRecommendation?.title}
                  </span>
                </div>
                <div className="description" >{parse(activeRecommendation?.description)} </div>
                <div className="moving-btn">
                  <button
                    onClick={()=>{onQuizDetailsRedirection(activeRecommendation?.id)
                    }}>
                    <img src={ImageUrl+"/event-section/rocket.svg"} style={{margin:"0 12px 0 0px"}}/>
                    {isWellness?"Start the Quiz ":"Quiz"}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="imageContainer">
                <div className="imageWrapper">
                  {Array.isArray(RecommendedEventData) && RecommendedEventData.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setActiveRecommendation(item);
                        setActiveIndex(index);
                      }}
                      style={{ marginLeft: "15px",cursor:"pointer" }}
                    >
                      <LazyImage
                        style={{
                          width: "128px",
                          height: "80px",
                          borderRadius: "6px",
                          border: activeIndex === index && "2px solid white",
                          boxShadow: "0px 8px 25px 0px rgba(0, 92, 135, 0.20)",
                        }}
                        src={ImageUrl + "/" + item.image}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </RecommendedContainer>
      )}
    </React.Fragment>
  );
};

RecommendedQuiz.propTypes = {
  RecommendedEventData: PropTypes.array,
  margin: PropTypes.string,
  isActivity: PropTypes.bool,
  history: PropTypes.object.isRequired,
  RecommendedEventDataLoading: PropTypes.bool,
  themesHeader: PropTypes.object,
  // heading: PropTypes.string,
  isWellness: PropTypes.bool
};

const mapStateToProps = (state) => ({
  themesHeader: state.profileData.themesHeader
});

export default connect(mapStateToProps, null)((withTranslation())(RecommendedQuiz));